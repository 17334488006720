var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"signInDetailsObserver"},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"i-sign-in-email"}},[_vm._v(" "+_vm._s(_vm.$t('SignInEmail'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"signInEmail","rules":"required|email|min:3|max:255","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-sign-in-email","disabled":"","type":"email","size":"lg"},model:{value:(_vm.signInEmail),callback:function ($$v) {_vm.signInEmail=$$v},expression:"signInEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"i-password"}},[_vm._v(" "+_vm._s(_vm.$t('Password'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"password","rules":"required|password","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"i-password","type":_vm.passwordFieldType,"size":"lg"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('MentorRegistrationReferral'))+" ")]),_c('b-form-group',[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","placeholder":_vm.$t('Choose'),"label":"text","options":_vm.userReferralOptions},model:{value:(_vm.referrals),callback:function ($$v) {_vm.referrals=$$v},expression:"referrals"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.referralFreeTextVisible)?_c('b-form-group',[_c('label',{attrs:{"for":"i-referral-free-text"}},[_vm._v(" "+_vm._s(_vm.$t('MentorRegistrationReferralFreeText'))+" ")]),_c('validation-provider',{attrs:{"name":"referralFreeText","rules":"min:3|max:200","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-referral-free-text"},model:{value:(_vm.referralFreeText),callback:function ($$v) {_vm.referralFreeText=$$v},expression:"referralFreeText"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2429541816)})],1):_vm._e()],1)],1),_c('b-row',{staticClass:"my-1"},[_c('b-col',[_c('b-form-checkbox',{attrs:{"value":"1"},model:{value:(_vm.giveConsent),callback:function ($$v) {_vm.giveConsent=$$v},expression:"giveConsent"}},[_vm._v(" "+_vm._s(_vm.$t('MentorGiveConsent'))+" ")])],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"acceptTermsAndConditions","rules":{
            required: {
              allowFalse: false
            }
          },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"value":"1"},model:{value:(_vm.acceptTermsAndConditions),callback:function ($$v) {_vm.acceptTermsAndConditions=$$v},expression:"acceptTermsAndConditions"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('I agree to'))+" "),_c('b-link',{attrs:{"to":{ name: 'mentoring-terms' },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('the terms and conditions of WHEN'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('and'))+" "),_c('b-link',{attrs:{"to":{ name: 'privacy-policy' },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('the privacy policy'))+" ")]),(_vm.showMentorFields)?[_vm._v(" "+_vm._s(_vm.$t('as a mentor'))+" ")]:_vm._e(),(_vm.showMenteeFields)?[_vm._v(" "+_vm._s(_vm.$t('as a mentee'))+" ")]:_vm._e(),_c('required-field')],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }