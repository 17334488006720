<template>
  <div>
    <page-header
      class="mb-2"
      :hide-message="!showIntro"
    >
      <template #title>
        {{ $t('BecomeMentor') }}
      </template>
      <template
        v-if="showIntro"
        #message
      >
        {{ $t('MentorRegistrationIntroMessage') }}
      </template>
    </page-header>
    <mentor-registration-intro
      v-if="showIntro"
      @show-form="showFormIfCanProceed"
    />
    <template v-else>
      <form-wizard
        color="#804470"
        :title="null"
        :subtitle="null"
        :back-button-text="$t('Back')"
        :next-button-text="$t('Next')"
        class="steps-transparent mb-2 mentor-registration"
        @on-change="scrollToTop"
      >
        <tab-content
          class="mb-2"
          :title="$t('Mentoring languages')"
          icon="feather icon-flag"
          :before-change="validateMentoringLanguagesStep"
        >
          <mentoring-languages ref="mentoringLanguagesStep" />
        </tab-content>
        <tab-content
          v-if="!isMentee"
          class="mb-2"
          :title="$t('AccountDetails')"
          icon="feather icon-user"
          :before-change="validatePersonalDetailsStep"
        >
          <personal-details
            ref="personalDetailsStep"
            show-mentor-fields
          />
        </tab-content>
        <tab-content
          v-if="!isMentee"
          class="mb-2"
          :title="$t('ProfessionalDetails')"
          icon="feather icon-briefcase"
          :before-change="validateProfessionalDetailsStep"
        >
          <professional-details
            ref="professionalDetailsStep"
            show-mentor-fields
          />
        </tab-content>
        <tab-content
          class="mb-2"
          :title="$t('MentorRole')"
          icon="feather icon-info"
          :before-change="validateMentorRoleStep"
        >
          <mentor-role ref="mentorRoleStep" />
        </tab-content>
        <tab-content
          class="mb-2"
          :title="$t('MentorProfile')"
          icon="feather icon-user-check"
          :before-change="validateMentorProfileStep"
        >
          <mentor-profile ref="mentorProfileStep" />
        </tab-content>
        <tab-content
          v-if="!isMentee"
          class="mb-2"
          :title="$t('SignInDetails')"
          icon="feather icon-lock"
          :before-change="validateSignInDetailsStep"
        >
          <sign-in-details
            ref="signInDetailsStep"
            show-mentor-fields
          />
        </tab-content>
        <template #finish>
          <b-button
            variant="primary"
            :disabled="submitInProgress"
            @click="formSubmitted"
          >
            {{ $t('SubmitApplication') }}
          </b-button>
        </template>
      </form-wizard>
      <required-field-explanation />
    </template>
    <switch-to-mentor-profile-modal
      v-if="isUserLoggedIn"
      ref="switch-to-mentor-profile-modal"
    />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import MentorRegistrationIntro from '@mentoring-platform/views/components/blocks/Register/MentorRegistrationIntro.vue'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'
import MentoringLanguages from '@mentoring-platform/views/components/blocks/MentoringLanguages.vue'
import PersonalDetails from '@mentoring-platform/views/components/blocks/PersonalDetails.vue'
import ProfessionalDetails from '@mentoring-platform/views/components/blocks/ProfessionalDetails.vue'
import MentorRole from '@mentoring-platform/views/components/blocks/MentorRole.vue'
import MentorProfile from '@mentoring-platform/views/components/blocks/MentorProfile.vue'
import SignInDetails from '@mentoring-platform/views/components/blocks/Register/SignInDetails.vue'
import RequiredFieldExplanation from '@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import LoginMixin from '@mentoring-platform/mixins/LoginMixin'
import ScrollToTopMixin from '@mentoring-platform/mixins/ScrollToTopMixin'
import SwitchToMentorProfileModal from '@mentoring-platform/views/components/blocks/SwitchToMentorProfileModal.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    FormWizard,
    MentoringLanguages,
    MentorRegistrationIntro,
    MentorRole,
    MentorProfile,
    PageHeader,
    PersonalDetails,
    ProfessionalDetails,
    RequiredFieldExplanation,
    TabContent,
    SignInDetails,
    SwitchToMentorProfileModal,
  },
  mixins: [
    LoginMixin,
    ScrollToTopMixin,
  ],
  data() {
    return {
      showIntro: true,
      submitInProgress: false,
    }
  },
  computed: {
    ...mapGetters('user', {
      isUserLoggedIn: 'isUserLoggedIn',
      hasMentorAccount: 'getHasMentorAccount',
    }),
    isMentee() {
      return this.$can('read', 'mentee')
    },
  },
  methods: {
    showFormIfCanProceed() {
      if (!this.isUserLoggedIn) {
        this.showForm()
      } else if (!this.hasMentorAccount) {
        this.showForm()
      } else {
        this.$refs['switch-to-mentor-profile-modal'].show()
      }
    },
    showForm() {
      this.showIntro = false
    },
    validateMentoringLanguagesStep() {
      const isValid = this.$refs.mentoringLanguagesStep.isValid()
      if (isValid) {
        this.$bus.$emit('mentor-registration-locales-filled', this.$refs.mentoringLanguagesStep.collectData().selected_languages)
      }
      return isValid
    },
    validatePersonalDetailsStep() {
      const isValid = this.$refs.personalDetailsStep.isValid()
      if (isValid) {
        this.$bus.$emit('mentor-registration-first-step-email-filled', this.$refs.personalDetailsStep.collectData().email)
      }
      return isValid
    },
    validateProfessionalDetailsStep() {
      return this.$refs.professionalDetailsStep.isValid()
    },
    validateMentorRoleStep() {
      return this.$refs.mentorRoleStep.isValid()
    },
    validateMentorProfileStep() {
      const visibleMentorLinks = this.$refs.mentorProfileStep?.mentorLinks.filter(link => link.visible) || []
      const hasInvalidLink = visibleMentorLinks.some(link => (!link.url.startsWith('https://') && !link.url.startsWith('http://')) || link.url.includes(' '))

      if (hasInvalidLink) {
        this.$root.$bvToast.toast(
          this.$t('Please make sure that the links you’ve entered start with http:// or https:// and don’t contain any spaces.'),
          {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          },
        )
        return false
      }
      return this.$refs.mentorProfileStep.isValid()
    },
    validateSignInDetailsStep() {
      return this.$refs.signInDetailsStep.isValid()
    },
    collectData() {
      if (this.isMentee) {
        return {
          ...this.$refs.mentoringLanguagesStep.collectData(),
          ...this.$refs.mentorRoleStep.collectData(),
          ...this.$refs.mentorProfileStep.collectData(),
        }
      }
      return {
        ...this.$refs.mentoringLanguagesStep.collectData(),
        ...this.$refs.personalDetailsStep.collectData(),
        ...this.$refs.professionalDetailsStep.collectData(),
        ...this.$refs.mentorRoleStep.collectData(),
        ...this.$refs.mentorProfileStep.collectData(),
        ...this.$refs.signInDetailsStep.collectData(),
        locale: this.$i18n.locale,
      }
    },
    async formSubmitted() {
      const isValid = this.isMentee ? await this.validateMentorProfileStep() : await this.validateSignInDetailsStep()
      if (!isValid) {
        return
      }
      const data = this.collectData()
      if (this.isMentee) {
        this.submitInProgress = true
        this.$store.dispatch('user/menteeCreateMentorProfile', data)
          .then(response => {
            this.submitInProgress = false
            this.$store.dispatch('user/me')
            this.$router.push({ name: 'mentee-mentorships' })
            this.showSuccess(response)
          })
          .catch(error => {
            this.submitInProgress = false
            this.showError(error)
          })
      } else {
        this.submitInProgress = true
        this.$store.dispatch('user/mentorRegister', data)
          .then(response => {
            this.submitInProgress = false
            this.showSuccess(response)
            this.$router.push({ name: 'home' })
          })
          .catch(error => {
            this.submitInProgress = false
            this.showError(error)
          })
      }
    },
    showSuccess() {
      this.$root.$bvToast.toast(this.$t('Your mentor application has been registered.'), {
        title: this.$t('Confirmation'),
        variant: 'success',
        solid: true,
      })
    },
    showError(error) {
      this.$root.$bvToast.toast(
        Object.keys(error?.errors)?.some(key => key.includes('mentor_links'))
          ? this.$t('Please make sure that the links you entered in the previous step are correct.')
          : this.$t('An error occured, please try again later'),
        {
          title: this.$t('Caution'),
          variant: 'danger',
          solid: true,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mentor-registration{
  .wizard-tab-content, .wizard-card-footer {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .wizard-card-footer {
    padding-bottom: 30px;
  }
}
</style>
